import Vue, { VueConstructor } from 'vue'
import { RouteConfig } from 'vue-router'

// eslint-disable-next-line
import { langDefault, langAvailable, segments } from '@/inc/app.config'
import View from '@/views/View.vue'

// Format segments to (a|b)
// eslint-disable-next-line
const formatSegment = (key: string): string =>
  Array.isArray(segments[key]) ? `(${(segments[key] as string[]).join('|')})` : (segments[key] as string)

let routes: RouteConfig[] = [
  {
    path: '',
    name: 'Home',
    component: () => import(/* webpackChunkName: 'v-Home' */ '@/views/Home.vue'),
    meta: {
      ssr: false,
      // DEV
      // static: true,
      // root: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: 'v-dashboard' */ '@/views/Dashboard.vue'),
    meta: {
      auth: true,
      ssr: false,
      static: true,
      // DEV
      // root: true,
    },
  },
  {
    path: '/archive',
    name: 'Archive',
    component: () => import(/* webpackChunkName: 'v-archive' */ '@/views/Dashboard.vue'),
    meta: {
      auth: true,
      ssr: false,
      static: true,
      // DEV
      // root: true,
    },
    props: {
      archive: true,
    },
  },
  {
    path: '/legal',
    name: 'Legal',
    component: () => import(/* webpackChunkName: 'v-legal' */ '@/views/Legal.vue'),
    meta: {
      auth: true,
      ssr: false,
      static: true,
      // DEV
      // root: true,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: 'v-profile' */ '@/views/Profile.vue'),
    meta: {
      auth: true,
      ssr: false,
      static: true,
      // DEV
      // root: true,
    },
  },
  {
    path: '/loan/archive',
    name: 'ArchivedLoans',
    component: () => import(/* webpackChunkName: 'v-loan-archive' */ '@/views/ArchivedLoans.vue'),
    meta: {
      auth: true,
      ssr: false,
      static: true,
      // DEV
      // root: true,
    },
  },
  {
    path: '/loan/:id',
    name: 'SingleLoan',
    component: () => import(/* webpackChunkName: 'v-single-loan' */ '@/views/SingleLoan.vue'),
    meta: {
      type: 'loan',
      auth: true,
      ssr: false,
      static: true,
      // DEV
      // root: true,
    },
  },
  {
    path: '/mortgage/:id',
    name: 'SingleMortgage',
    component: () => import(/* webpackChunkName: 'v-single-loan' */ '@/views/SingleLoan.vue'),
    meta: {
      type: 'mortgage',
      auth: true,
      ssr: false,
      static: true,
      // DEV
      // root: true,
    },
  },
  {
    path: '/login/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: 'v-login' */ '@/views/Login.vue'),
    meta: {
      ssr: false,
      static: true,
    },
  },
  {
    path: '/documents/sign',
    name: 'DocumentsSign',
    component: () => import(/* webpackChunkName: 'v-sign' */ '@/views/documents/Sign.vue'),
    meta: {
      auth: true,
      ssr: false,
      static: true,
    },
  },
  {
    path: ':slug/:sub?',
    name: 'Page',
    component: () => import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      // api: 'https://elantis-client-space-api-v1.staging.epic-sys.io',
      ssr: false,
    },
  },
  {
    path: '*',
    name: 'All',
    component: () => import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: false,
    },
  },
]

// If multilanguage, add lang segment
if (langAvailable.length > 1) {
  const initialRoutes = [...routes]
  routes = [
    {
      path: `/:lang(${langAvailable.join('|')})`,
      component: View as VueConstructor<Vue>,
      meta: {
        // ScrollTop all the time for all routes
        scrollTop: true,
      },
      children: initialRoutes
        .filter(r => r.meta?.root !== true)
        .map(r => {
          // Remove leading slash
          r.path = r.path.replace(/^\//, '')

          return r
        }),
    },
    {
      path: '/',
      redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
    },
  ]

  routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))
}

export { routes }
