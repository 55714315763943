import ismobilejs from 'ismobilejs'
export const enableDashboard = () => {
  window.localStorage.setItem('d', '1')
}

export const disableDashboard = () => {
  window.localStorage.removeItem('d')
}

/**
 * Dashboard is enabled when users uses login bypass (?u=xxx)
 *
 * Dashboard is disabled if there is login token in url (?token=xxx)
 */
export const isDashboardEnabled = () => Boolean(window.localStorage.getItem('d') === '1')

export const isMobile = () => ismobilejs(window.navigator)
