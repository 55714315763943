/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { captureException } from '@sentry/vue'
// import axios from 'axios'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

type GitLabNotification = {
  title: string
  description: string
  // eslint-disable-next-line camelcase
  start_time: string
  service: string
  hosts: string
  severity: string
  fingerprint: string
  gitlab_environment_name: string
  extra: Record<string, any>
}

const { VUE_APP_RELEASE } = process.env
const isProd = VUE_APP_RELEASE === 'production' || VUE_APP_RELEASE === 'pre'

function notifyGitLab(data: GitLabNotification) {
  // axios.post('/_notify', data)
  console.log(data)
}

export function report(error: Error, data: Record<string, any> = {}) {
  if (!isProd) {
    return
  }

  captureException(error, {
    extra: data.extra,
  })

  const gitLabNotificationData: GitLabNotification = {
    title: data.title || data.extra?.title,
    description: data.description,
    start_time: dayjs().utc().format(),
    service: 'elantis-espace-client-v1',
    hosts: typeof window === 'undefined' ? 'called from nodejs' : decodeURI(window.location.href),
    severity: 'high',
    fingerprint: uuidv4(),
    extra: {
      ...(data.extra || {}),
      time: dayjs.utc().local().format('DD-MM-YYYY HH:mm'),
    },
    gitlab_environment_name: VUE_APP_RELEASE || '',
  }

  notifyGitLab(gitLabNotificationData)
}
